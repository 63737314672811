<template>
    <div v-if="productReview" class="tile__rating">
        <span
            class="icon icon--star"
            :class="starImg(star)"
            v-for="(star, index) in rating"
            :key="'modelID_' + star + index"
        ></span>
        <p class="tile__rating-text">
            {{ reviewCount }} {{ $t("message.reviews") }}
        </p>
    </div>
</template>

<script>
import { range } from 'underscore';
import { mapState } from 'vuex';

const fillZero = (arr) => {
	return arr.reduce(
		(a, b, i) => {
			const val = b > 1 ? 1 : b;

			a[i] = val;

			return a;
		},
		[0, 0, 0, 0, 0]
	);
};

export default {
	props: {
		modelID: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			paging: {},
			rollup: {}
		};
	},
	computed: {
		...mapState('productResults', ['filteredProducts', 'productReviews']),
		productReview() {
			const filteredProduct = this.productReviews[this.modelID];

			if (typeof filteredProduct !== 'undefined'
				&& filteredProduct.paging.total_results > 0
				&& filteredProduct.results[0].rollup.average_rating !== null
			) {

				return filteredProduct;

			}

			return false;
		},
		reviewCount() {
			return this.productReview ? this.productReview.paging.total_results : 0;
		},
		averageRating() {
			return this.productReview ? this.productReview.results[0].rollup.average_rating : 0;
		},
		rating() {  //eslint-disable-line
			const flr = Math.floor(this.averageRating);
			const qrt = Math.floor(this.averageRating * 4) / 4;
			const rem = qrt % flr;
			const ran = range(1, flr + 1);

			if (rem) {
				ran.push(rem);
			}

			return fillZero(ran);
		}
	},
	watch: {
		productReview() {
			this.$emit('powerReviewsHasData', {
				rate: this.averageRating,
				count: this.reviewCount
			});
		}
	},
	methods: {
		// eslint-disable-next-line complexity
		starImg(star) {
			switch (star) {
				case 0:
					return 'icon--star-0';
				case 0.25:
					return 'icon--star-1-4';
				case 0.5:
					return 'icon--star-1-2';
				case 0.75:
					return 'icon--star-3-4';
				case 1:
					return 'icon--star-1';
				default:
					return '';
			}
		}
	}
};
</script>
