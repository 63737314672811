<template>
    <div v-if="!query.uid" class="dealer fs-unmask">
        <dialog-modal
            :isOpen="isWhyDealerModalOpen"
            @close="toggleWhyDealerModal"
            :ariaLabel="$t('message.whyDealers')"
        >
            <span v-html="primary"></span>
        </dialog-modal>

        <div class="row">
            <div class="col-12">
                <div class="header__page-heading" v-html="headline"></div>

                <button @click="toggleWhyDealerModal" class="dealer__show-modal">
                    {{ $t("message.whyDealers") }}
                </button>
            </div>

            <div class="col-12 col-md-5 col-lg-4 dealer__margin-location-box">
                <location-box :query="query" data-cy="dealerLocationSearch" />
            </div>

            <div
                class="col-12 col-md-5 col-lg-6 dealer__margin-content dealer-selection-cms"
                v-html="primary"
            ></div>
        </div>

        <!-- DEALER LIST -->
        <div class="row">
            <div class="col-12 col-md-10 dealer__margin-both-sides">
                <dealer-list @dealerSelected="selectDealer" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import DealerList from '@/components/DealerList.vue';
import LocationBox from '@/components/LocationBox.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { structuredProductQuery } from '@/utils/product-query-builder';
import DialogModal from '@/components/DialogModal';

export default {
	title: 'Dealer',

	props: {
		query: {
			required: true,
			type: Object
		}
	},

	data() {
		return {
			isWhyDealerModalOpen: false
		};
	},

	components: {
		DealerList,
		LocationBox,
		DialogModal
	},

	computed: {
		...mapState('content/dealer', ['headline', 'primary']),
		...mapState('dealer', ['selected']),
		...mapState('products', ['noTires']),
		...mapState('products/verify', ['needsVerified', 'selectedSize']),
		...mapState('initial', ['title']),

		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.dealerSelectPage') + ' | ' + this.title;

			return page;
		}
	},

	methods: {
		...mapActions('content/dealer', ['fetchContent']),
		...mapActions('dealer', ['select']),
		...mapActions('products', ['setNoTires']),
		...mapMutations('cart/order', ['leadSearchType']),
		...mapMutations('cart', ['resetCart']),
		...mapActions('products/verify', ['fetchTireSizes']),
		...mapState('consumerInfo', ['userLanguage']),

		async movePages(dealerUID) {
			await axios.delete('/service/cart').then(() => {
				const qs = Object.assign({}, this.query, { uid: dealerUID });

				if (this.needsVerified) {
					this.$router.push({
						path: '/app/verify',
						query: qs
					});
				} else {
					this.$router.push({
						path: '/app/product',
						query: Object.assign({}, qs, {
							tiresize: this.selectedSize
						})
					});
				}
			});
		},
		selectDealer: async function (dealer) {
			const { websiteUID } = dealer;

			this.query.uid = websiteUID;
			await this.resetCart();

			// We can fetch the tire sizes for the car while we fetch dealer info.
			// moving on must wait for both: the emit triggers going to the verify screen
			// which needs the uid from the select, and the 'need verify or not'
			// binary decision from fetchTireSizes
			let { error } = await Promise.all([
				this.select(this.query),
				this.fetchTireSizes(structuredProductQuery(this.query))
			]);

			if (error) {
				axios.get('/service/errorlog', {
					params: {
						message: 'error when selecting dealer or fetching tire sizes',
						error,
						query: this.query
					}
				});
			}

			const qs = Object.assign({}, this.query, {
				zipCode: this.zipCode,
				uid: this.selected.uid
			});

			if (this.noTires) {
				await this.$router.push({
					path: '/app/no-products',
					query: qs
				});
			} else {
				this.movePages(websiteUID);
			}
		},

		toggleWhyDealerModal() {
			this.isWhyDealerModalOpen = !this.isWhyDealerModalOpen;
		}
	},

	watch: {
		noTires(res) {
			const qs = Object.assign({}, this.query, {
				zipCode: this.zipCode,
				uid: this.selected.uid
			});

			if (res) {
				this.$router.push({
					path: '/app/no-products',
					query: qs
				});
			}
		},
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},

	mounted() {
		document.title = this.newPageTitle;
	},

	created() {
		this.fetchContent();

		// Call action from products to reset "noTires" in case someone clicks back
		// And tries again (caused issues on the product page)
		this.setNoTires(false);

		if (this.query.isbysize) {
			return this.leadSearchType({
				type: 'searchByTireSize'
			});
		}

		return this.leadSearchType({
			type: 'searchByVehicle'
		});
	}
};
</script>
