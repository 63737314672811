<!-- The location box component is used to create the box that gathers a users geolocation or zip code.

Its basic functionality is to create the location box and then emit an event once a zip or the button
for geolocation is clicked -->

<template>
    <form class="location" @submit.prevent="locationUpdate()">
        <div class="row location__input-wrapper">
            <div class="col-9 location__zip">
                <label class="location__label" for="zipCode">
                    {{ $t("message.zipCode") }}
                </label>
                <input
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    :aria-required="true"
                    :minlength="zipMinLength"
                    :maxlength="zipMaxLength"
                    v-model="zipCode"
                    data-vv-name="zipCode"
                    data-cy="dealerZipCode"
                    :class="[
                        { 'input__field--error': errors.has('zipCode') },
                        'form__input',
                        'location__input',
                    ]"
                    v-validate="{
                        required: true,
                        regex: zipRegEx
                    }"
                    class="safe-input"
                />
                <div role="alert">
                    <small v-if="errors.has('zipCode')" class="input__error-reason">
                        {{ errors.first("zipCode") }}
                    </small>
                </div>
            </div>

            <button
                type="submit"
                class="col-3 location__search-button button button--primary"
                data-cy="zipCodeSearchButton"
            >
                <i class="location__search-button-icon icon icon--search"></i>
                <span class="sr-only">Search</span>
            </button>
        </div>

        <p class="location__text">{{ $t("message.or") }}</p>

        <div class="row">
            <button
                class="col-12 location__currentLoc"
                type="button"
                @click="getLocation"
            >
                {{ $t("message.currentLoc") }}
            </button>
        </div>
    </form>
</template>

<script>
import { dealerUseCurrentLocationSuccess, dealerEnterZipAlert } from '@/utils/setDataLayer';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			country: ''
		};
	},
	created() {
		this.getCountry();
	},
	computed: {
		...mapState('dealer', ['defaultZipRadius']),
		zipCode: {
			get() {
				return this.$store.state.dealer.zipCode;
			},
			set(value) {
				this.updateZipCode(value);
			}
		},
		zipMinLength: function () {
			if (this.dealerCountry === 'ca') {
				return 6;
			}

			return 5;
		},
		zipMaxLength: function () {
			if (this.dealerCountry === 'ca') {
				return 7;
			}

			return 5;
		},
		zipRegEx: function() {
			// Check for country specific zip codes
			if (this.dealerCountry === 'ca') {
				return new RegExp(/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i);
			}

			// Default zip codes to US pattern
			return new RegExp(/^((\d{5}-\d{4})|(\d{5}))$/);
		},
		dealerCountry: function() {
			return this.country.toLowerCase();
		}
	},
	methods: {
		...mapMutations('dealer', ['updateZipCode']),
		...mapActions('dealer', ['dealerLocationListRequest', 'fetchDealerCountry']),
		geoLocation({ coords }) {
			dealerUseCurrentLocationSuccess(coords);

			this.dealerLocationListRequest({
				latitude: coords.latitude,
				longitude: coords.longitude
			});
		},
		getLocation() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(this.geoLocation);
			}
		},
		locationUpdate() {
			// TODO: ADD ZIPVALIDATOR STEP
			this.$validator.validateAll().then((isZipValid) => {
				if (isZipValid) {
					this.dealerLocationListRequest({
						zipCode: this.zipCode
					});

					return;
				}

				dealerEnterZipAlert({
					alertUserEntry: this.zipCode,
					alertCategory: 'Invalid User Entry',
					alertMessage: 'Not a Valid Entry'
				});

			});
		},
		getCountry() {
			this.fetchDealerCountry().then(({ data }) => {
				this.country = data;
			});
		}
	}
};
</script>
