<template>
    <section class="wrap">
        <h2 class="header__leaving-site-heading">
            {{$t("message.leavingSiteWarning", { programName: programName })}}
        </h2>
        <p>
            {{$t("message.leavingSiteText", { oemName: program.programDisplayName })}}
            <br>
            <br>
            <strong>
                {{$t("message.leavingSiteQuestion")}}
            </strong>
        </p>
        <div class="row">
            <button type="button" @click="closeWindow" class="button-leaving-site button-leaving-site__secondary button">
                {{ $t("message.cancel") }}
            </button>
            <button type="button" @click="goToSite" class="button-leaving-site button button--primary">
                {{ $t("message.continue") }}
            </button>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { leavingPage } from '@/utils/setDataLayer';

export default {
	name: 'LeavingSite',
	props: {
		query: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			url: '',
			program: {
				tireProgramConsumerName: 'This website'
			}
		};
	},
	methods: {
		...mapActions('dealer', ['fetchProgram']),
		goToSite() {
			window.location.href = `http://${this.url}`;
		},
		closeWindow() {
			window.close();
		}
	},
	computed: {
		...mapState('dealer', [
			'selected'
		]),
		programName() {
			return this.program.tireProgramConsumerName
				? this.program.tireProgramConsumerName : this.program.tireProgramName;

		}
	},
	created() {
		this.url = this.query.url;
		this.fetchProgram().then(({ data }) => {
			this.program = data;
		});
		leavingPage('url', this.url);
	}
};

</script>
