<template>
    <main class="row fs-unmask">
        <modal-out-of-stock />

        <dialog-modal
            :isOpen="inventoryUnavailable"
            @close="toggleInventoryUnavailableModal(false)"
            :ariaLabel="inventoryUnavailableHeader"
        >
            <h4 slot="header">{{ inventoryUnavailableHeader }}</h4>
            <p>{{ inventoryUnavailableMessage }}</p>
            <button
                slot="footer"
                @click="toggleInventoryUnavailableModal(false)"
                class="button button--primary"
            >
                OK
            </button>
        </dialog-modal>

        <!-- CMS Content -->
        <div v-html="headline" class="col-lg-6 header__page-heading"></div>

        <!-- Evox and Vehicle Info -->
        <div class="col-lg-6 vehicle-info">
            <vehicle-info
                :isMobile="isMobile"
                :query="query"
                :tireCount="allProducts.length"
                :tireSize="selectedSize"
                :tireLoading="!allProducts.length && !noTires"
            />
        </div>

        <!-- Tire Coach -->
        <aside class="col-12 col-lg-4">
            <!-- CMS Content -->
            <div v-html="primary" data-cy="tireCoachPrimaryContent"></div>
            <div v-html="secondary" data-cy="tireCoachSecondaryContent"></div>
        </aside>

        <!-- Product Results -->
        <div class="col-lg-8">
            <!-- Sorting and Filtering -->
            <results-organizer
                :fadeSidebar="fadeSidebar"
                :query="query"
                data-cy="resultsOrganizer"
            />

            <!-- Content Placeholder while fetching products -->
            <div v-if="isLoadingProducts" class="product__content-placeholder">
                <content-placeholder
                    v-for="(n, i) in 5"
                    :key="i"
                    :rows="placeholderRows"
                    class="product__content-placeholder-tile"
                />
            </div>

            <!-- Tires & Wheel Packages -->
            <tabs v-else @activated="toggleSideBar">
                <!-- Tires -->
                <tab name="t1" title="message.tires">
                    <transition-group
                        name="product-items"
                        tag="section"
                        data-cy="productResultsContainer"
                        @after-enter="onAfterEnter"
                    >
                        <product-tile-tire
                            v-for="(productSet, index) in filteredProducts"
                            :key="productSet.products[0].productId"
                            :productSet="productSet"
                            :query="query"
                            :isMobileInstallAvailable="isMobileInstallAvailable"
                            :tileIndex="index"
                            :financingDisclaimer="financingDisclaimer"
                            :showCompareCheckbox="showCompareCheckbox"
                        />
                    </transition-group>
                </tab>

                <!-- Winter Wheel Packages -->
                <tab v-if="hasWpksToSell" name="t2" title="message.wheelPKG">
                    <transition-group
                        name="product-items"
                        tag="section"
                        data-cy="productResultsContainer"
                    >
                        <product-tile-package
                            v-for="(wheelPackage, index) in wpks"
                            :key="wheelPackage.sku"
                            :wheelPackage="wheelPackage"
                            :query="query"
                            :tileIndex="index"
                        />
                    </transition-group>
                </tab>
            </tabs>
        </div>

        <div
            v-if="selected.hasFinancingOTS && selected.financePartnerCode"
            class="col-12 product__finance-footer"
        >
            <p v-html="estimatedPaymentDisclaimer" class="finance-disclaimer"></p>
        </div>

        <!-- CMS Content -->
        <div v-html="ad" class="col-12"></div>

        <section class="col-12">
            <compare-drawer :query="query" />
        </section>
    </main>
</template>

<script>
import axios from 'axios';
import CompareDrawer from '@/components/CompareDrawer.vue';
import ProductTileTire from '@/components/ProductTileTire.vue';
import ProductTilePackage from '@/components/ProductTilePackage.vue';
import ResultsOrganizer from '@/components/ProductTileResultsOrganizer.vue';
import Tab from '@/components/ProductTabsTabPane.vue';
import Tabs from '@/components/ProductTabsTabGroup.vue';
import TireCoach from '@/components/TireCoach.vue';
import VehicleInfo from '@/components/VehicleInfo.vue';
import WheelTile from '@/components/WheelPkgsTile.vue';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { isEmpty } from 'underscore';
import { structuredProductQuery } from '@/utils/product-query-builder';
import ContentPlaceholder from 'vue-content-placeholder';
import ModalOutOfStock from '@/components/ModalOutOfStock';
import DialogModal from '@/components/DialogModal.vue';
import ResourceNames from '../../plugins/constants/resourceNames';

export default {
	title: 'Product',

	props: {
		query: {
			required: true,
			type: Object
		}
	},

	components: {
		CompareDrawer,
		ProductTileTire,
		ProductTilePackage,
		ResultsOrganizer,
		Tab,
		Tabs,
		TireCoach,
		VehicleInfo,
		WheelTile,
		ContentPlaceholder,
		ModalOutOfStock,
		DialogModal
	},

	data() {
		return {
			fadeSidebar: false,
			windowWidth: window.innerWidth,
			isLoadingProducts: true,
			program: {},
			placeholderRows: [
				{
					height: '25px',
					boxes: [[0, '100px']]
				},
				{
					height: '25px',
					boxes: [
						[0, '100px'],
						['10%', 1]
					]
				},
				{
					height: '25px',
					boxes: [[0, '100px']]
				},
				{
					height: '25px',
					boxes: [
						[0, '100px'],
						['10%', 2]
					]
				},
				{
					height: '2rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, 5]]
				},
				{
					height: '1rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, '50%']]
				},
				{
					height: '1rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, '10em']]
				}
			],
			b2cWheelPackagesToSell: false
		};
	},

	methods: {
		...mapActions('content/products', ['fetchContent']),
		...mapActions('dealer', ['select', 'fetchProgram']),
		...mapActions('products/verify', ['fetchTireSizes']),
		...mapActions('productResults', ['pushDataLayer', 'runRecigne']),
		...mapMutations('products', ['setProductSetsToCompare']),
		...mapMutations('modal', ['toggleInventoryUnavailableModal']),
		...mapMutations('products/verify', ['selectSize']),
		toggleSideBar(tab) {
			this.fadeSidebar = tab === 1;
		},
		onAfterEnter() {
			if (this.selectedModelId) {
				document
					.querySelector(
						`#tile-${this.selectedModelId} form .tile__quantity-input`
					)
					.focus();
			}
		},
		updatePage() {
			const productQuery = structuredProductQuery(this.query);

			if (this.query.tiresize) {
				this.selectSize(this.query.tiresize);
			} else {
				// Check our lightweight call to see if we should go to verify or not
				// At this point we SHOULD already know
				// This is a catch for the just in case situation
				this.fetchTireSizes(productQuery).then(() => {
					if (this.needsVerified && !this.query.tiresize) {
						this.$router.push({
							path: '/app/verify',
							query: this.query
						});
					}
					const qs = Object.assign({}, this.query, {
						zipCode: this.zipCode,
						uid: this.selected.uid
					});

					if (this.noTires) {
						this.$router.push({
							path: '/app/no-products',
							query: qs
						});
					}
				});
			}
		},
		updateWindowWidth() {
			this.windowWidth = window.innerWidth;
		},
		fetchB2cWheelPackagesToSell() {
			axios.get(`/app/resources/${ResourceNames.useWheelPackages}`).then((res) => {
				this.b2cWheelPackagesToSell = res.data && res.data.ResourceName === ResourceNames.useWheelPackages;
			});
		}
	},

	computed: {
		...mapGetters('products', ['wpks']),
		...mapGetters('initial', ['getPowerReviews']),
		...mapState('content/products', [
			'ad',
			'headline',
			'primary',
			'secondary'
		]),
		...mapState('dealer', ['selected']),
		...mapState('initial', ['phoneNumber', 'title', 'powerReviews']),
		...mapState('modal', ['inventoryUnavailable']),
		...mapState('products', ['allProducts', 'selectedProdName', 'noTires', 'selectedModelId']),
		...mapState('productResults', ['filteredProducts']),
		...mapState('products/verify', ['needsVerified', 'selectedSize']),
		...mapState('consumerInfo', ['userLanguage']),

		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.productPage') + ' | ' + this.title;

			return page;
		},
		financingDisclaimer() {
			if (!this.selected.financePartner) {
				return null;
			}

			return this.selected.financePartner;
		},
		estimatedPaymentDisclaimer() {
			if (!this.selected.financePartner) {
				return null;
			}

			return this.selected.financePartner.estimatedPaymentDisclaimer;
		},
		hasWpksToSell() {
			// NOTE: this is a janky solution to handle just AUD for now
			// If find yourself adding a 2nd OEM, please consider creating
			// a new resource to handle Wheel Packages
			return this.wpks.length && this.b2cWheelPackagesToSell;
		},
		isMobile() {
			return this.windowWidth < 768;
		},
		isMobileInstallAvailable() {
			return (
				this.selected.transports.length && this.selected.transports.find((transport) => transport.id === 14));
		},
		showCompareCheckbox() {
			return this.filteredProducts.length > 1;
		},
		vehicleYear() {
			return Number(this.query.year);
		},
		inventoryUnavailableHeader() {
			return this.$t('message.inventoryUnavailableHeader');
		},
		inventoryUnavailableMessage() {
			return this.$t('message.inventoryUnavailable', {
				tire: this.selectedProdName
			});
		}
	},

	mounted() {
		window.addEventListener('resize', this.updateWindowWidth);
		document.title = this.newPageTitle;
	},

	watch: {
		'$store.state.consumerInfo.userLanguage': function () {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},

	async created() {
		this.isLoadingProducts = true;
		await axios.delete('/service/cart');
		this.setProductSetsToCompare([]);

		this.fetchProgram()
			.then(({ data }) => {
				this.program = data;
			})
			.catch((err) => {
				throw new Error(err);
			});

		this.fetchContent()
			.then(async () => {
				if (isEmpty(this.selected)) {
					const { uid, make } = this.query;

					await this.select({
						uid,
						make
					}).then(() => {
						this.updatePage();
					});
				} else {
					this.updatePage();
				}
			})
			.catch((err) => {
				throw new Error(err);
			})
			.finally(() => {
				this.isLoadingProducts = false;
			});

		this.fetchB2cWheelPackagesToSell();
	}
};
</script>
