<template>
    <section class="col-12">

        <!-- SHOPBLOCK FINANCING TERMS-->
        <div class="tile__finance" v-if="!this.financingAmounts" >
            <p v-html="disclaimerText1Computed"></p>

            <!-- SHOW/HIDE DETAILS -->
            <div class="tile__finance-header">
                <button
                    class="tile__finance-header-button"
                    @click="displayFinanceTerms"
                    :aria-expanded="isFinanceOpen ? 'true' : 'false'"
                    :aria-label="`Expand to learn more about ${ this.tireBrandAndModel } financing`"
                >
                    <i class="tile__finance-arrow-down icon icon--angle-down" :class="{ open: isFinanceOpen }"></i>
                    {{ $t("message.learnMore") }}
                </button>
            </div>

            <!-- FINANCING OFFER TERMS DETAIL -->
            <div v-if="!isCreditCard" class="tile__finance-details"
                 :class="{ 'is-hidden' : !isFinanceOpen }">
                <h4>{{ $t("message.financeEstimatedFor", { qty:this.tireQuantity }) }}</h4>
                <table>
                    <tr>
                        <td>{{ financeTerm }} {{ $t("message.financeMonthlyPaymentOf") }}:</td>
                        <td class="finance-table-amounts">{{ $n(monthlyPayment , 'currency')}}</td>
                    </tr>
                    <tr v-if="productSet.financing.downPayment">
                        <td>{{ $t("message.financeDownPayment") }}:</td>
                        <td class="finance-table-amounts">{{ $n(downPayment , 'currency') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("message.financeAmountFinanced") }}:</td>
                        <td class="finance-table-amounts">{{ $n(loanAmount , 'currency') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("message.financeAPR") }}:</td>
                        <td class="finance-table-amounts">{{ financedRate }}%</td>
                    </tr>
                    <tr>
                        <td class="finance-table-footer" colspan="2">
                            {{ $t("message.financeSelectDescAtCheckout", { description: this.financingDisclaimer.description }) }}
                        </td>
                    </tr>
                </table>
            </div>

            <!-- CREDIT CARD OFFER DETAILS -->
            <div v-if="isCreditCard" class="tile__finance-details"
                 :class="{ 'is-hidden' : !isFinanceOpen }">
                <p class="cc-header">{{ $t("message.alreadyHave")}} {{ this.financingDisclaimer.disclaimerText2 }}? </p>
                <p v-if="hasCreditCardCheckout">{{ $t("message.useYourCard")}}</p>
                <p v-else>{{ $t("message.useYourCardAppointment")}}</p>
                <p class="cc-header">{{ $t("message.ccDontHave")}} {{ this.financingDisclaimer.disclaimerText2 }}?</p>
                <p>{{ $t("message.ccSelectThe")}} {{ this.financingDisclaimer.disclaimerText2 }} {{ $t("message.ccToApply")}}</p>
            </div>

            <!-- FINANCE PARTNER LOGO -->
            <div v-if="!isCreditCard" class="tile__finance-footer">
                <img :src="partnerLogoURL" :alt="partnerLogoName" />
                <span><sup>&dagger;</sup>{{ $t("message.financeRateTerms")}}</span>
            </div>

            <div v-if="isCreditCard" class="tile__finance-footer">
                <p class="finance-disclaimer"><sup>&dagger;</sup>{{ $t("message.financeSubjectApproval")}}</p>
            </div>
        </div>

        <!-- CHECKOUT PAGE FINANCING TERMS -->
        <div v-if="this.financingAmounts">
            <div v-if="!isCreditCard" class="checkout__finance">
                <h5>{{ $t("message.financeAvailable")}} {{ this.financingDisclaimer.description }}</h5>
                <div class="checkout__finance-panel-container">
                    <div class="checkout__finance-partner-panel">
                        <img :src="partnerLogoURL" :alt="partnerLogoName" />
                        <h6 v-html="disclaimerText3Computed"></h6>
                        <p>{{ $t("message.financeIfApproved")}}</p>
                        <p>&dagger;{{ $t("message.financeRateTerms")}}</p>
                    </div>
                    <div v-if="monthlyPayment" class="checkout__finance-terms-panel">
                        <table>
                            <tr>
                                <td>{{ financeTerm }} {{ $t("message.financeMonthlyPaymentOf") }}:</td>
                                <td class="finance-table-amounts">{{ $n(monthlyPayment , 'currency')}}</td>
                            </tr>
                            <tr v-if="financingAmounts.downPayment">
                                <td>{{ $t("message.financeDownPayment") }}:</td>
                                <td class="finance-table-amounts">{{ $n(downPayment , 'currency') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t("message.financeAmountFinanced") }}:</td>
                                <td class="finance-table-amounts">{{ $n(loanAmount , 'currency') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t("message.financeAPR") }}:</td>
                                <td class="finance-table-amounts">{{ financedRate }}%</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- CHECKOUT PAGE CREDIT CARD TERMS -->
        <div v-if="this.financingAmounts">
            <div v-if="isCreditCard" class="checkout__finance">
                <h5>{{ this.financingDisclaimer.creditCardHeader }}</h5>
                <div class="checkout__finance-header-container">
                    <h6 v-html="this.financingDisclaimer.creditCardOffer"></h6>
                    <p class="finance-disclaimer" v-html="this.financingDisclaimer.checkoutApprovedText"></p>

                    <!-- SHOW/HIDE DETAILS -->
                    <button class="checkout__finance-header" @click.self="displayFinanceTerms">
                        <i class="checkout__finance-arrow-down icon icon--angle-down"></i>
                    Learn More</button>
                </div>

                <div class="checkout__finance-panel-container"
                     :class="{ 'is-hidden' : !isFinanceOpen }">

                    <div class="checkout__finance-credit-panel">
                        <h6>{{ $t("message.alreadyHave")}} {{ this.financingDisclaimer.disclaimerText2 }}?</h6>
                        <div v-if="hasCreditCardCheckout"><p>{{ $t("message.useYourCard")}}</p></div>
                        <div v-else><p>{{ $t("message.useYourCardAppointment")}}</p></div>
                    </div>
                    <div class="checkout__finance-credit-panel">
                        <h6>{{ $t("message.dontHave")}} {{ this.financingDisclaimer.disclaimerText2 }}?</h6>
                        <p>{{ $t("message.selectThe")}} {{ this.financingDisclaimer.disclaimerText2 }}
                        {{ $t("message.boxAtCheckout")}}</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {
	name: 'FinancingData',
	props: {
		productSet: {
			type: Object
		},
		financingAmounts: {
			type: Object
		},
		financingDisclaimer: {
			type: Object
		},
		tireQuantity: {
			type: Number
		}
	},
	data() {
		return {
			isFinanceOpen: false,
			hasRate: false
		};
	},
	computed: {
		disclaimerText1Computed() {
			return this.disclaimerTextReplace(this.financingDisclaimer.disclaimerText1);
		},
		disclaimerText3Computed() {
			return this.disclaimerTextReplace(this.financingDisclaimer.disclaimerText3);
		},
		hasCreditCardCheckout() {
			return this.$store.state.initial.hasCreditCardCheckout;
		},
		isCreditCard() {
			if (!this.monthlyPayment) {
				return true;
			}

			return false;
		},
		partnerLogoName() {
			if (this.financingDisclaimer) {
				return `${this.$t('message.financing')} ${this.$t('message.availableThrough') } ${ this.financingDisclaimer.description}`;

			}

			return `finance partner logo`;

		},
		partnerLogoURL() {
			if (this.financingDisclaimer) {
				return `/dist/assets/finance/${this.financingDisclaimer.code}_logo.png`.toLowerCase();
			}

			return null;
			// note that credit cards have no logo
		},
		financedRate() {
			if (this.financingAmounts) {
				return this.financingAmounts.rate.toFixed(2);
			}
			if (this.productSet.financing.rate) {
				return this.productSet.financing.rate.toFixed(2);
			}

			return null;
		},
		monthlyPayment() {
			if (this.financingAmounts) {
				return this.financingAmounts.monthlyPayment;
			}
			if (this.productSet && this.productSet.financing) {
				return this.productSet.financing.monthlyPayment;
			}

			return null;
		},
		financeTerm() {
			if (this.financingAmounts) {
				return this.financingAmounts.term;
			}
			if (this.productSet.financing.term) {
				return this.productSet.financing.term;
			}

			return null;
		},
		downPayment() {
			if (this.financingAmounts) {
				return this.financingAmounts.downPayment;
			}
			if (this.productSet.financing.downPayment) {
				return this.productSet.financing.downPayment;
			}

			return null;
		},
		loanAmount() {
			if (this.financingAmounts) {
				return this.financingAmounts.loanAmount;
			}
			if (this.productSet.financing.loanAmount) {
				return this.productSet.financing.loanAmount;
			}

			return null;
		},
		tireBrandAndModel() {
			return `${ this.productSet.products[0].brand } ${ this.productSet.products[0].catalogEntryDescription }`;
		}
	},
	methods: {
		disclaimerTextReplace(disclaimerTextIn) {

			let paymentCurrency = this.$options.filters.currency(this.monthlyPayment);
			let substituted = (disclaimerTextIn).replace('{monthlyPayment}', paymentCurrency);

			substituted = substituted.replace('{qty}', this.tireQuantity);
			substituted = substituted.replace('{description}', this.financingDisclaimer.description);

			return substituted;

		},

		displayFinanceTerms() {
			this.isFinanceOpen = !this.isFinanceOpen;
		}
	}
};
</script>
