import axios from 'axios';
import ensureArray from '@/utils/ensure-array';

export const setPromotionsForOrder = ({ commit }, payload) =>
	commit('setOrder', { promotions: ensureArray(payload) });

export const consumerOrderQueue = async ({ commit }, payload) => {
	const { error } = await axios.post('/service/order', payload);

	if (error) {
		throw error;
	}
	commit('consumerOrderQueueSubmission', payload);
};

export const consumerLead = async (ctx, payload) => {
	const { error } = await axios.post('/service/consumerlead', payload);

	if (error) {
		throw new Error(error);
	}
};


export const signatureRequest = ({ commit }, payload) => axios
	.post('/service/signaturelistrequest', payload)
	.then(({ data }) => {
		commit('setSignature', data);
	})
	.catch(err => {
		commit('setSignature', '');
		throw new Error(err);
	});

export const consumerLeadInitial = async ({ commit }, payload) => {
	const { data: { id }, error } = await axios.post('/service/consumerlead/initial', payload);

	if (error) {
		commit('setLeadID', String(''));

		throw new Error(error);
	}

	return id ? commit('setLeadID', String(id)) : commit('setLeadID', String(id));

};

export const setConsumer = ({ commit }, data) => {
	const orderInfo = {
		consumer: {
			firstName: data.firstName,
			lastName: data.lastName,
			address: {
				line1: data.address.line1,
				line2: data.address.line2,
				city: data.address.city,
				state: data.address.state,
				zipCode: data.address.zipCode,
				country: 'US'
			},
			email: data.email,
			phone: data.phone,
			cellPhone: '',
			contactPreference: '',
			comments: data.comments,
			selectedFinancing: data.selectedFinancing
		},
		payer: {
			firstName: data.firstName,
			lastName: data.lastName,
			address: {
				line1: data.address.line1,
				line2: data.address.line2,
				city: data.address.city,
				state: data.address.state,
				zipCode: data.address.zipCode,
				country: 'US'
			}
		}
	};

	if (data.deliveryAddress) {
		orderInfo.consumer.deliveryAddress = {
			firstName: data.deliveryAddress.firstName,
			lastName: data.deliveryAddress.lastName,
			line1: data.deliveryAddress.line1,
			line2: data.deliveryAddress.line2,
			city: data.deliveryAddress.city,
			state: data.deliveryAddress.state,
			zipCode: data.deliveryAddress.zipCode,
			country: 'US'
		};
	}

	commit('setOrder', orderInfo);
};

// MAYBE REMOVE ONCE WE ARE DONE A/B TESTING
// export const setMobileInstallZip = ({ commit }, zip) => axios({
// 	method: 'POST',
// 	// Safari fix
// 	url: `${'/service/consumerZip?nocache='}${ new Date().getTime()}`,
// 	data: {zip}
// })

