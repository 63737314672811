<template>
    <div class="compare-row__header grid-row" role="row">

        <!-- NORMAL HEADER -->
        <header-cell
            v-for="(productSet, productSetIndex) in productSetsToCompare"
            :key="productSetIndex"
            :productSet="productSet"
            :isMobile="isMobile"
            :selectedDealer="selectedDealer"
        />

        <!-- DROPDOWN TO ADD A NEW CART TO COMPARE -->
        <header-swap v-if="minimumQtyToCompare" />

        <!-- EXTRA BLANK ROW -->
        <div class="compare-table__spec"/>
    </div>
</template>

<script>
import HeaderSwap from '@/components/CompareHeaderSwap.vue';
import HeaderCell from '@/components/CompareHeaderCell.vue';
import { mapState } from 'vuex';


export default {
	props: {
		minimumQtyToCompare: {
			type: Boolean,
			required: true
		},
		selectedDealer: {
			type: Object,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	},
	components: {
		HeaderSwap,
		HeaderCell
	},
	computed: {
		...mapState('products', [
			'productSetsToCompare'
		])
	}
};
</script>
