<template>
    <footer class="compare-drawer" role="list" v-show="atLeastOneProductSet">
        <drawer-tile
            v-for="productSet in productSetsToCompare"
            role="listitem"
            :key="productSet.products[0].productId"
            :productSet="productSet"
        />
        <!-- BLANK TILES IF LESS THAN 3 TIRES ARE BEING COMPARED -->
        <div
            class="compare-drawer__tile"
            v-for="(blankTile, blankTileIndex) in blankTiles"
            role="listitem"
            :key="'blankTile' + blankTileIndex"
        >
            <div class="compare-drawer__img-blank">
                <p class="compare-drawer__count">{{ blankTile }}</p>
            </div>
        </div>

        <div class="compare-drawer__button-wrapper">
            <button
                v-if="atLeastOneProductSet"
                class="button button--primary compare-drawer__button"
                :disabled="notEnoughProductSets"
                @click="goToCompare"
            >
                {{ $t("message.compare") }}
            </button>
        </div>
    </footer>
</template>

<script>
import DrawerTile from '@/components/CompareDrawerDrawerTile.vue';
import { noDataEvent } from '@/utils/setDataLayer';
import { mapMutations, mapState } from 'vuex';
import { extend, map } from 'underscore';

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	components: {
		DrawerTile
	},
	computed: {
		...mapState('products', ['isStaggered', 'productSetsToCompare']),
		atLeastOneProductSet() {
			return this.productSetsToCompare.length;
		},
		blankTiles() {
			let maxCount = 3;
			let countArray = [];
			let numberOfIterations = maxCount - this.productSetsToCompare.length;

			for (numberOfIterations; numberOfIterations > 0; numberOfIterations--) {
				countArray.unshift(maxCount);
				maxCount--;
			}

			return countArray;
		},
		notEnoughProductSets() {
			return this.productSetsToCompare.length < 2;
		},
		// Maps each compare tire's Ids to add to the query string when going to compare page
		selectedProductSetIds() {
			if (this.isStaggered) {
				return map(this.productSetsToCompare, function (productSet) {
					return [productSet.products[0].productId, productSet.products[1].productId];
				});
			}

			return map(this.productSetsToCompare, function (productSet) {
				return [productSet.products[0].productId];
			});
		}
	},
	methods: {
		...mapMutations('products', ['setSingleProductSetToCompare']),
		goToCompare() {
			noDataEvent('productResultsEngageCompareButton');
			this.setSingleProductSetToCompare([this.productSetsToCompare[0]]);

			this.$router.push({
				path: '/app/compare',
				query: extend(this.query, {
					id: this.selectedProductSetIds
				})
			});
		}
	}
};
</script>
