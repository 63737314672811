import '@babel/polyfill';
import promise from 'es6-promise';

promise.polyfill();

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { dateTimeFormats, numberFormats } from '@/i18nConfig';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueMask from 'v-mask';
import VeeValidate, { Validator } from 'vee-validate';
import Vue2Filters from 'vue2-filters';
import PortalVue from 'portal-vue';

import Appointment from '@/page/Appointment.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import Compare from '@/page/Compare.vue';
import ConsumerInfo from '@/page/ConsumerInfo.vue';
import Dealer from '@/page/Dealer.vue';
import DealerHeader from '@/components/DealerHeader.vue';
import DealerFooter from '@/components/DealerFooter.vue';
import DealerContact from '@/components/DealerContact.vue';
import Details from '@/page/ProductDetail.vue';
import Home from '@/page/Home.vue';
import NoDealer from '@/page/NoDealer.vue';
import NoDealerForm from '@/page/NoDealerFormAndContact.vue';
import NoDealerFail from '@/page/NoDealerFailure.vue';
import NoDealerSuccess from '@/page/NoDealerSuccess.vue';
import NoProds from '@/page/NoProducts.vue';
import NoProdsForm from '@/page/NoProductsFormAndContact.vue';
import NoProdsFail from '@/page/NoProductsFailure.vue';
import NoProdsSuccess from '@/page/NoProductsSuccess.vue';
import Payment from '@/page/Payment.vue';
import Product from '@/page/Product.vue';
import Receipt from '@/page/Receipt.vue';
// import Schedule from '@/page/Schedule.vue';
import Verify from '@/page/Verify.vue';
import LeavingSite from '@/page/LeavingSite';
import FSLConfirmation from '@/page/FSLConfirmation';
import Error500 from '@/page/Error500.vue';

import appointmentStore from '@/store/appointment';
import carlineStore from '@/store/carline';
import dealerStore from '@/store/dealer.js';
import intialStore from '@/store/initial';
import mobileInstallationStore from '@/store/mobile-installation.js';
import productDetailStore from '@/store/product-details';
import productResultsStore from '@/store/product-results';
import locales from './locales';
import productStore from '@/store/products';
import scheduleStore from '@/store/schedule';
import serviceOfferingsStore from '@/store/serviceOfferings';
import cartStore from '@/store/cart';
import contentStore from '@/store/content';
import modalStore from '@/store/modal';
import consumerInfoStore from '@/store/consumer-info';
import Axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUserSecret);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('cookie-consent', CookieConsent);
Vue.component('locale-switcher', LocaleSwitcher);
Vue.component('dealer-header', DealerHeader);
Vue.component('dealer-footer', DealerFooter);
Vue.component('dealer-contact', DealerContact);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueMask);
Vue.use(Vue2Filters);
Vue.use(PortalVue);

const routes = [
	{
		path: '/app',
		component: Home,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/dealer',
		component: Dealer,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/product',
		component: Product,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/details',
		component: Details,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/schedule',
		component: Appointment,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/payment',
		component: Payment,
		props: (route) => ({query: route.query})
	},
	{
		path: '/app/leaving-site',
		component: LeavingSite,
		props: (route) => ({query: route.query})
	},
	{
		path: '/app/receipt',
		component: Receipt,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/verify',
		component: Verify,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/compare',
		component: Compare,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/consumer-info',
		component: ConsumerInfo,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/app/no-dealer',
		component: NoDealer,
		props: (route) => ({ query: route.query }),
		children: [
			{
				path: '',
				component: NoDealerForm,
				name: 'noDealerForm',
				props: (route) => ({ query: route.query })
			},
			{
				path: 'success',
				name: 'noDealerSuccess',
				component: NoDealerSuccess,
				props: (route) => ({ query: route.query })
			},
			{
				path: 'fail',
				name: 'noDealerFail',
				component: NoDealerFail,
				props: (route) => ({ query: route.query })
			}
		]
	},
	{
		path: '/app/no-products',
		component: NoProds,
		props: (route) => ({ query: route.query }),
		children: [
			{
				path: '',
				component: NoProdsForm,
				name: 'noProdsForm',
				props: (route) => ({ query: route.query })
			},
			{
				path: 'success',
				name: 'noProdsSuccess',
				component: NoProdsSuccess,
				props: (route) => ({ query: route.query })
			},
			{
				path: 'fail',
				name: 'noProdsFail',
				component: NoProdsFail,
				props: (route) => ({ query: route.query })
			}
		]
	},
	{
		path: '/app/fsl-confirm',
		component: FSLConfirmation,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/error/500',
		component: Error500,
		props: (route) => ({ query: route.query })
	},
	{
		path: '*',
		component: Home,
		props: (route) => ({ query: route.query })
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior () {
		return {
			x: 0,
			y: 0
		};
	}
});

const store = new Vuex.Store({
	modules: {
		appointment: appointmentStore,
		carline: carlineStore,
		cart: cartStore,
		consumerInfo: consumerInfoStore,
		content: contentStore,
		dealer: dealerStore,
		initial: intialStore,
		modal: modalStore,
		productDetail: productDetailStore,
		productResults: productResultsStore,
		products: productStore,
		schedule: scheduleStore,
		serviceOfferings: serviceOfferingsStore,
		mobileInstallation: mobileInstallationStore
	}
});

// We will use this to build a list of custom error messages from the locales/translate data.
function buildCustomLocaleDictionary(data) {
	let dictionary = {};

	Object.keys(data).forEach(localeCode => {
		let specificAttributeMessages = {};

		for (const [messageKey, messageValue] of Object.entries(data[localeCode]['message'])) {
			// The validation.messages is mainly used to make sure that we have our specific messages instead of generic ones
			// We remove it after we split so techinically it can be anything unique
			if (messageKey.startsWith('validation.messages')) {
				let messageParts = messageKey.split('.');
				// The last part will be our key for determining the value of the message
				let last = messageParts.pop();

				// remove the 'validation' and 'messages' part of the key since we don't need them
				messageParts.splice(0, 2);

				// We will drill down into the message parts and build nested objects based on the messageParts
				messageParts.reduce((o, k) => {
					o[k] = o[k] || {};

					return o[k];
				}, specificAttributeMessages)[last] = messageValue;
			}
		}

		dictionary[localeCode] = {};
		dictionary[localeCode].custom = specificAttributeMessages;
	});

	return dictionary;

}

const vmObject = {
	value: {
		$t: function(str) {
			return str;
		}
	}
};

// Create VueI18n instance with options

Axios.get(`/service/translate`).then(async ({data}) => {
	const defaultLocale = Object.keys(data).find(locale => {
		return locale === window.navigator.language.toLocaleLowerCase();
	}) || Object.keys(data)[0];

	await Axios.post('/service/language', { languageCode: defaultLocale });
	const i18n = new VueI18n({
		// set initial locale
		locale: defaultLocale,
		messages: data,
		dateTimeFormats,
		numberFormats
	});

	Validator.localize(data);

	Vue.use(VeeValidate, {
		i18n,
		dictionary: buildCustomLocaleDictionary(data)
	});

	const app = new Vue({
		i18n,
		router,
		store,
		mixins: [Vue2Filters.mixin]
	});

	vmObject.value = app;

	app.$mount('#app');

}).catch(() => {
	const i18n = new VueI18n({
	// if translations fail, still render the app using US translations
		locale: 'en-us',
		messages: {
			'en-us': {
				message: locales
			}
		},
		dateTimeFormats,
		numberFormats
	});

	Validator.localize('en', locales);

	Vue.use(VeeValidate, {
		i18n,
		dictionary: {
			'en-us': {
				messages: buildCustomLocaleDictionary(locales)
			}
		}
	});

	const app = new Vue({
		i18n,
		router,
		store,
		mixins: [Vue2Filters.mixin]
	});

	vmObject.value = app;


	app.$mount('#app');
});


export const vm = vmObject;
