<template>
    <div class="compare-shop">
        <form class="compare-shop__wrapper" @submit.prevent="checkInventory">

            <!-- STAGGERED QTY -->
            <div v-if="isStaggered" class="compare-shop__staggered-wrapper">
                <div class="compare-shop__label-wrapper--stag">
                    <label class="compare-shop__label" for="staggered-front"
                    >{{ $t("message.front") }}
                        <input
                            class="compare-shop__qty-input tile__quantity-input"
                            type="number"
                            id="staggered-front"
                            :min="this.locQty.rear != 0 ? 0 : 1"
                            max="2"
                            @change="qtyChange()"
                            aria-label="Select Front Tire Quantity"
                            v-model.number="locQty.front"
                        />
                    </label>
                </div>

                <div class="compare-shop__label-wrapper--stag">
                    <label class="compare-shop__label" for="staggered-rear"
                    >{{ $t("message.rear") }}
                        <input
                            class="compare-shop__qty-input tile__quantity-input"
                            type="number"
                            id="staggered-rear"
                            :min="this.locQty.front != 0 ? 0 : 1"
                            max="2"
                            @change="qtyChange()"
                            aria-label="Select Rear Tire Quantity"
                            v-model.number="locQty.rear"
                        />
                    </label>
                </div>
            </div>

            <!-- LOOSE QTY -->
            <div v-else class="compare-shop__label-wrapper">
                <label class="compare-shop__label" for="square-set"
                >{{ $t("message.qty") }}
                    <input
                        class="compare-shop__qty-input tile__quantity-input"
                        type="number"
                        id="square-set"
                        min="1"
                        max="4"
                        @change="qtyChange()"
                        :aria-label="'Select ' + productSet.products[0].brand + ' Tire Quantity'"
                        v-model.number="locQty.front"
                    />
                </label>
            </div>

            <!-- STAGGERED BUTTON -->
            <div v-if="isStaggered" class="compare-shop__button-wrapper--stag">
                <statefulBtn
                    class="compare-shop__button"
                    :aria-label="'Choose ' + this.tireBrandAndModel  +  ' Tires'"
                    :isLoading="isLoading"
                    :disabled="isDisabled"
                >
                    {{ $t("message.choose") }}
                </statefulBtn>
            </div>
            <!-- LOOSE BUTTON -->
            <div v-else class="compare-shop__button-wrapper">
                <statefulBtn
                    class="compare-shop__button"
                    :aria-label="'Choose ' + this.tireBrandAndModel  +  ' Tires'"
                    :isLoading="isLoading"
                >
                    {{ $t("message.choose") }}
                </statefulBtn>
            </div>
        </form>
    </div>
</template>

<script>
import statefulBtn from '@/components/StatefulButton.vue';
import { productSkuEvent } from '@/utils/setDataLayer';
import { mapActions, mapMutations, mapState } from 'vuex';
import { extend, isEmpty } from 'underscore';

export default {
	props: {
		productSet: {
			type: Object,
			required: true
		}
	},
	components: {
		statefulBtn
	},
	data() {
		return {
			isLoading: false,
			locQty: {}
		};
	},
	computed: {
		...mapState('modal', ['oos']),
		...mapState('products', ['deliveryDate', 'isStaggered', 'qtySelected', 'defaultQty']),
		isDisabled() {
			// Disable the button if there is 0 qty for front and rear
			return !this.locQty.rear && !this.locQty.front;
		},
		tireBrandAndModel() {
			return `${this.productSet.products[0].brand } ${ this.productSet.products[0].catalogEntryDescription}`;

		}
	},
	watch: {
		qtySelected([front, rear]) {
			this.locQty = {
				front,
				rear
			};
		}
	},
	methods: {
		...mapActions('cart', ['addTireToCart']),
		...mapActions('products', ['getInventory']),
		...mapMutations('products', ['setProductName', 'updateQty']),
		async checkInventory() {
			const { products } = this.productSet;

			this.isLoading = true;
			const skusAndQty = await this.getInventory({
				products,
				qty: isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected
			});

			if (!skusAndQty) {
				this.isLoading = false;
				throw new Error('No inventory');
			}

			this.isLoading = false;
			this.confirmTirePurchase(products, skusAndQty);
		},
		confirmTirePurchase(products, skusAndQty) {
			if (!this.oos && skusAndQty) {
				skusAndQty[0].promos = this.promos;

				this.addTireToCart(skusAndQty)
					.then(() => {
						this.goToSchedule();
					})
					.catch((err) => {
						throw new Error(err);
					});
			} else {
				this.setProductName(products[0].catalogEntryDescription);
			}
		},
		goToSchedule() {
			productSkuEvent('comparePageAddToCart', this.productSet);
			this.$router.push({
				path: '/app/schedule',
				query: extend(this.$route.query, {
					localBest: this.productSet.localBestSeller || false,
					qty: this.qtySelected.join(',') || this.defaultQty,
					sku: this.productSet.products.length > 1 ? this.productSet.products.map(prod => prod.sku).join() : this.productSet.products[0].sku
				})
			});
		},
		qtyChange() {
			// update the quantity so all tire prices update together
			let newQty = [this.locQty.front];

			if (typeof this.locQty.rear !== 'undefined') newQty.push(this.locQty.rear);
			this.updateQty(newQty);
		}
	},
	beforeMount() {
		const [front, rear] = isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected;

		this.locQty = {
			front,
			rear
		};
	}
};
</script>
