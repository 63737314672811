<template>
    <div class="div">
        <h2>{{$t('message.thankyou')}}</h2>
        <p>{{$t('message.tireExpert')}} {{vehicleInfo}}.</p>
    </div>
</template>

<script>
export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	computed: {
		vehicleInfo() {
			const { isbysize, make } = this.query;

			if (isbysize) {
				const { width, ratio, diameter } = this.query;

				return `${make} ${width}/${ratio}/${diameter}`;
			}

			const { year, model, trim, option} = this.query;

			return `${year} ${make} ${model} ${trim} ${option}`;
		}
	}
};
</script>
