import axios from 'axios';

const getStateFromSession = () => {
	return axios.get('/service/receiptDataRequest')
		.then(({ data }) => data)
		.catch((err) => {
			throw new Error(err);
		});
};


const saveStateToSession = async (state) => {
	const { cart, initial } = state;
	const { details } = cart.order;

	const receipt = {
		consumer: details.consumer,
		hasCreditCardCheckout: initial.hasCreditCardCheckout,
		leadID: details.leadID,
		leadSearchType: details.leadSearchType,
		payment: details.payment,
		powerReviews: initial.powerReviews,
		pricing: cart.tiresInCart.pricing,
		products: details.products,
		mobileInstallInfo: details.consumer.deliveryAddress,
		vehicle: details.vehicle
	};

	await axios.post('/service/receiptDataRequest', { receipt: receipt });
};


export {
	getStateFromSession,
	saveStateToSession
};

