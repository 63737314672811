<template>
    <div class="fs-unmask">
        <dealer-header />
        <dealer-contact />
        <locale-switcher v-if="multiLanguageSupport" darkMode />
        <tabs>
            <tab name="tabpanel0" title="message.byVehicle" dataCy="shopByVehicleTab" aria-selected="true" aria-labelledby="tab-0">
                <shop-by-vehicle
                    :query="query"
                    :target="target"
                    data-cy="shopByVehicle"
                >
                </shop-by-vehicle>
            </tab>
            <tab v-if="hasSearchBySize" name="tabpanel1" title="message.bySize" dataCy="shopBySizeTab" aria-selected="false" aria-labelledby="tab-1">
                <shop-by-tire-size
                    :query="query"
                    :target="target"
                    tabindex="-1"
                    data-cy="shopBySize"
                >
                </shop-by-tire-size>
            </tab>
        </tabs>

        <dealer-footer />
        <cookie-consent/>
        <dialog-modal
            :isOpen="isModalOpen"
            @close="closeTheModal"
            :ariaLabel="$t('message.ieSupportHeader')"
        >
            <h4 slot="header">{{ $t('message.ieSupportHeader') }}</h4>
            <p>{{ $t('message.ieSupportMessage') }}</p>
        </dialog-modal>
    </div>
</template>

<script>

import CookieConsent from '@/components/CookieConsent.vue';
import ShopByVehicle from '@/components/ShopByVehicle.vue';
import ShopByTireSize from '@/components/ShopByTireSize.vue';
import DealerHeader from '@/components/DealerHeader.vue';
import DealerFooter from '@/components/DealerFooter.vue';
import DealerContact from '@/components/DealerContact.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import Tabs from '@/components/BootstrapTabGroup.vue';
import Tab from '@/components/BootstrapTabPane.vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import DialogModal from '@/components/DialogModal';

export default {
	title: 'Home',

	props: {
		query: {
			type: Object,
			required: true
		}
	},

	components: {
		CookieConsent,
		ShopByVehicle,
		ShopByTireSize,
		Tabs,
		Tab,
		DialogModal,
		DealerHeader,
		DealerFooter,
		DealerContact,
		LocaleSwitcher
	},

	data() {
		return {
			isDemo: true,
			isModalOpen: false
		};
	},


	computed: {
		...mapState('content', ['spotlight', 'trivia', 'advertisement']),
		...mapState('initial', ['hasSearchBySize', 'title']),

		target() {
			if (this.inIframe()) {
				return '_blank';
			}

			return '_self';
		},
		multiLanguageSupport() {
			const localeMount = document.getElementById('language');

			return localeMount;
		},
		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.homepage') + ' | ' + this.title;

			return page;
		}
	},

	methods: {
		...mapActions('content', ['fetchDocs']),
		...mapActions('dealer', ['select']),
		...mapMutations('consumerInfo', ['setUserLanguage']),
		// determing if we're in an iframe. Assume yes if we hit an error
		inIframe() {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
		closeTheModal() {
			this.isModalOpen = false;
		},
		isIE() {
			const ua = window.navigator.userAgent;
			const msie = ua.indexOf('MSIE ');
			const trident = ua.indexOf('Trident/');

			if (msie > 0 || trident > 0) {
				this.isModalOpen = true;

				return true;
			}

			return false;
		}
	},

	watch: {
		spotlight(data) {
			const el = document.getElementById('spotlight');

			if (el && data) {
				el.innerHTML = data;
			}
		},
		trivia(data) {
			const el = document.getElementById('trivia');

			if (el && data) {
				el.innerHTML = data;
			}
		},
		advertisement(data) {
			const el = document.getElementById('advertisement');

			if (el && data) {
				el.innerHTML = data;
			}
		}
	},

	mounted() {
		document.title = this.newPageTitle;

		this.isIE();
	},


	async created() {
		const { uid, make } = this.query;
		const storedLocale = window.localStorage.getItem('userLocale');

		if (storedLocale) {
			this.$i18n.locale = storedLocale;
			this.setUserLanguage(this.$i18n.locale);
		}

		if (uid) {
			await this.select({
				uid,
				make
			});
		}

		this.fetchDocs();
	}
};
</script>
