<template>
    <div class="row">
        <!-- Sorry Message -->
        <div class="col-12">
            <h2>{{ $t("message.sorry") }}</h2>
            <p>{{ $t("message.noProdSubHeader") }}</p>
        </div>

        <section class="col-12">
            <no-products-form :fromPath="fromPath" :query="query" />
        </section>
    </div>
</template>

<script>
import noProductsForm from '@/components/NoProductsForm.vue';

export default {
	// Pass query string in as properties
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			fromPath: ''
		};
	},
	components: {
		noProductsForm
	},
	// Path of the page that sent the consumer to no-products
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.fromPath = from.path;
		});
	}
};
</script>
