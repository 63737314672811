<template>
    <div class="fsl-confirm">
        <!-- Apr 2024: removing all mobile install content for now -->
        <!-- <div v-html="scheduleMobileModalConfirmation"></div> -->
        <button
            @click="handleBack"
            class="button button-primary"
        >Return to OTS
        </button>
    </div>
</template>

<script>
// import { mapState } from 'vuex';

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	title: 'FSLConfirmation',
	computed: {
		// ...mapState('content/schedule', ['scheduleMobileModalConfirmation'])
	},
	methods: {
		handleBack() {
			this.$router.push({
				path: '/app/schedule',
				query: this.query
			});
		}
	}
};
</script>
