import axios from 'axios';

export const serviceOfferingsRequest = ({ commit }) => axios
	.get('/service/dealer/serviceOfferings')
	.then(({ data }) => {
		commit('setAvailableOfferings', data);
	}).catch(err => {
		commit('setAvailableOfferings', []);
		console.log('Error while serviceOfferingsRequest', err);
	});

// Appointments
export const getAppointment = async ({ commit }) => {
	const { data: appointment } = await axios.get('/service/appointment');

	commit('setAppointment', appointment);

	return appointment;
};

export const setMobileInstallZip = ({ commit }, zip) => axios({
	method: 'POST',
	// Safari fix
	url: `${'/service/consumerZip?nocache='}${ new Date().getTime()}`,
	data: {zip}
})
	// .post('/service/consumerZip', {zip} )
	.then(({ data }) => commit('setMobileInstallZip', data.zip))
	.catch(err => {
		commit('setMobileInstallZip', '');
		console.log('Error while setMobileInstallZip', err);
	});

export const getMobileInstallZip = ({ commit }) => axios
	.get('/service/consumerZip').then(({ data }) => {
		commit('setMobileInstallZip', data.zip);

		return data.zip;
	}).catch(err => {
		console.log('Error while getMobileInstallZip', err);
	});

export const setAppointment = async ({ commit }, appointment) => {
	commit('setIsSubmittingAppointment', true);

	try {
		const { error } = await axios.post('/service/appointment', appointment);

		if (error) {
			commit('setAppointment', {});
			commit('setIsSubmittingAppointment', false);
			throw new Error(error);
		}

		return commit('setAppointment', appointment);
	} catch (err) {
		console.log('Error while setting appoointment', err);
	} finally {
		commit('setIsSubmittingAppointment', false);
	}

	return true;
};

export const createAppointment = async ({ commit }, { consumer, vehicle, products, isSearchBySize, blockDurationMinutes, blockStartTime, requestedDate }) => {// eslint-disable-line max-len
	const mappedProducts = products.map(p => {
		return { itemId: p.itemId,
			qty: p.qty};
	});
	const { data: { appointmentID, provider }, error} = await axios.post('/service/createAppointment', {
		consumer,
		vehicle,
		products: mappedProducts,
		isSearchBySize,
		blockDurationMinutes
	});

	if (error) {
		commit('cart/order/setOrder', {}, { root: true });

		console.log('Error while createAppointment', error);

		return new Error(error);
	}

	const appointment = {
		apptID: appointmentID,
		apptProvider: provider,
		blockStartTime,
		requestedDate,
		blockDurationMinutes,
		status: 1
	};

	return commit('cart/order/setOrder', { appointment }, { root: true });
};
