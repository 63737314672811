<template>
    <div class="row fs-unmask">
        <div class="col-lg-5 ml-lg-auto">

            <div class="row">
                <div class="col-12 header__page-heading" v-html="headline"></div>
            </div>

            <div class="row" v-html="primary"></div>

            <form class="verify">

                <fieldset v-if="askSize" class="form-group">
                    <legend class="verify__fieldset-header">{{ $t("message.verifyDiffSizes") }}</legend>
                    <label class="verify__label" for="yes-staggered">
                        <input
                            @click="setType('staggered')"
                            id="yes-staggered"
                            :value="'staggered'"
                            name="sizeToDisplay"
                            type="radio"
                            class="form-check-input" />
                        {{ $t("message.yes") }}
                    </label>

                    <label class="verify__label" for="not-staggered">
                        <input
                            @click="setType('loose')"
                            id="not-staggered"
                            :value="'loose'"
                            name="sizeToDisplay"
                            type="radio"
                            class="form-check-input" />
                        {{ $t("message.no") }}
                    </label>
                </fieldset>

                <fieldset class="form-group" v-if="currentSizes.length > 1">
                    <legend class="verify__fieldset-header">{{ $t("message.verifyWhichSizeDiff") }}</legend>
                    <span
                        class="verify__label"
                        v-for="(size, index) in currentSizes"
                        :key="index">
                        <label :for="size.join(',')">
                            <template v-if="size.length > 1">
                                <input
                                    type="radio"
                                    class="form-check-input"
                                    name="size"
                                    :id="size.join(',')"
                                    @click="selectSize(size.join(','))"
                                    :value="size.join(',')" />
                                <p class="verify__paragraph">
                                    {{ $t("message.front") }}: {{size[0]}}
                                </p>
                                <p class="verify__paragraph">
                                    {{ $t("message.rear") }}: {{size[1]}}
                                </p>
                            </template>

                            <template v-else>
                                <input
                                    type="radio"
                                    class="form-check-input"
                                    name="size"
                                    :id="size.join(',')"
                                    @click="selectSize(size.join(','))"
                                    :value="size.join(',')" />
                                {{size.join(',')}}
                            </template>
                        </label>
                    </span>
                </fieldset>

                <button type="button" v-if="selectedSize" @click="goToProduct" class="button button--primary">
                    {{ $t("message.continue") }}
                </button>

            </form>
        </div>

        <div class="col-lg-6">
            <div class="row" v-html="secondary"></div>
        </div>

        <div class="row" v-html="ad"></div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { extend, isEmpty, some } from 'underscore';
import { structuredProductQuery } from '@/utils/product-query-builder';

const hasQuery = qs => some([ // eslint-disable-line complexity
	(qs.year && qs.make && qs.model && qs.trim),
	(qs.isbysize && qs.make && qs.width && qs.ratio && qs.diameter)
]);

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState('products/verify', ['askSize', 'currentSizes', 'selectedSize', 'selectedSet']),
		...mapState('content/verify', ['primary', 'secondary', 'ad', 'headline']),
		...mapState('dealer', ['selected']),
		...mapState('consumerInfo', ['userLanguage']),
		...mapState('initial', ['title']),

		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.verifyPage') + ' | ' + this.title;

			return page;
		},
		convertedStags() {
			if (this.sizesToDisplay === 'staggered') {
				return this.currentSizes.map(item => item.split(','));
			}

			return [];
		}
	},
	methods: {
		...mapActions('products/verify', ['fetchTireSizes']),
		...mapActions('content/verify', ['fetchContent']),
		...mapActions('dealer', { selectDealer: 'select' }),
		...mapMutations('products/verify', ['setCurrentSizes', 'selectSize']),
		goToProduct() {
			this.$router.push({
				path: '/app/product',
				query: extend(this.query, { tiresize: this.selectedSize })
			});
		},
		setType(type) {
			if (type !== this.selectedSet) {
				this.setCurrentSizes(type);
			}
		}
	},
	beforeMount() {
		const qs = this.query;

		if (!hasQuery(qs)) {
			this.$router.push('/', () => window.scrollTo(0, 0));
		}

		this.fetchContent();
	},
	mounted() {
		document.title = this.newPageTitle;
	},
	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},
	created() {
		if (isEmpty(this.selected)) {
			const qs = this.query;

			this.selectDealer({
				uid: qs.uid,
				make: qs.make
			});

			this.fetchTireSizes(structuredProductQuery(qs));
		}
	}
};
</script>
