<template>
    <div class="col-12">
        <div v-html="formLocationConfirmation"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState('content/noDealer', [
			'formLocationConfirmation'
		])
	}
};
</script>
