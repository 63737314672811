<template>
    <div class="row">
        <div class="col-12">
            <div v-html="formLocation"></div>
        </div>

        <section class="col-12">
            <no-dealer-form :query="query" />
        </section>
    </div>
</template>

<script>
import noDealerForm from '@/components/NoDealerForm.vue';
import { mapState, mapActions } from 'vuex';

export default {
	// Pass query string in as properties
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	components: {
		noDealerForm
	},
	computed: {
		...mapState('content/noDealer', ['formLocation']),
		...mapState('consumerInfo', ['userLanguage'])
	},
	methods: {
		...mapActions('content/noDealer', ['fetchContent'])
	},
	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},
	created() {
		this.fetchContent();
	}
};
</script>
