<template>
    <div class="fs-unmask">
        <!-- Mobile Install Unavailable -->
        <dialog-modal :isOpen="isDrsgOOS" :ariaLabel="$t('message.tireNoMobileHeader')">
            <h4 slot="header">{{ $t("message.tireNoMobileHeader") }}</h4>
            <p>{{ $t('message.tireNoMobile', { tire: selectedProdName }) }}</p>
        </dialog-modal>

        <modal-out-of-stock :isOpen="isOOS" />

        <!-- FSL Modal -->
        <modal-fsl-mobile-transaction :query="query" />

        <section class="row appointment-page">

            <!-- MOBILE INSTALL HEADLINE & TRIVIA CONTENT -->
            <!-- Apr 2024: removing mobile install content for now -->
            <!-- <div class="col-12" v-if="isMobile" >
                <div v-if="hasMobileInstall" v-html="mobileInstallContent1"></div>
                <div class="header__page-heading" v-html="headline"></div>
                <div v-html="applicableTrivia"></div>
            </div> -->

            <!--  Services and comments for desktop layout -->
            <div class="col-5 appointment-information" v-if="!isMobile">
                <!-- <div v-if="hasMobileInstall" v-html="mobileInstallContent1"></div> -->
                <div class="header__page-heading" v-html="headline"></div>
                <div v-html="applicableTrivia"></div>
                <h5 class="services-label" v-if="selected.serviceOfferings && selected.serviceOfferings.length > 0">
                    {{ $t("message.addServices") }}
                </h5>
                <!-- PICK YOUR SERVICES -->
                <appointment-services
                    v-if="selected.serviceOfferings && selected.serviceOfferings.length > 0"
                    :isMobile="isMobile"
                    :query="query"
                    :timeChosen="selectedAppointment.time.appointmentTime"
                    :date="selectedAppointment.date"
                    :areServicesOpen="true"
                    @updateAppointmentServices="updateAppointmentServicesSelected"
                > </appointment-services>
                <!-- CONSUMER COMMENTS -->
                <label
                    class="services__input-label services__comments-label fs-mask"
                    for="serviceComments"
                >
                    {{$t("message.comments")}}
                    <textarea
                        id="serviceComments"
                        name="serviceComments"
                        maxlength="400"
                        rows="3"
                        :placeholder="$t('message.servicesPlaceholder')"
                        v-model="selectedAppointment.comments"
                        data-vv-name="comments"
                        v-validate="{ regex: /^[a-zA-Z0-9 ,=/'@&()?$#.!\n\-]*$/ }"
                        :class="['services__comments-textarea',
                                 {'services__comments-textarea--error': errors.has('comments')}]"
                        data-cy="scheduleComments"
                    ></textarea>
                </label>
                <small
                    v-if="errors.has('comments')"
                    class="input__error-reason form__error"
                >{{ $t("message.commentsError") }}</small>
                <div v-if="financingProductAllowed">
                    <div v-if="!isCreditCard" class="schedule__finance-disclaimer">
                        <img :src="financePartnerLogoURL" :alt="financePartnerLogoName" />
                        <span v-html="financeShortText"></span>
                    </div>
                </div>
            </div>

            <!-- APPOINTMENT TYPE, DATE, TIME -->
            <div
                v-if="!isLoading"
                :class="{ 'col-12': isMobile, 'col-7': !isMobile }"
                aria-live="assertive"
                aria-busy="false"
            >
                <form @submit.prevent>
                    <section class="accordion-group form-group">
                        <!--  These are the desktop headings (tabs) for each component -->
                        <div class="schedule-navigation" v-if="!isMobile" role="tablist">
                            <appointment-heading
                                id="tab-1"
                                aria-controls="tabpanel-1"
                                :isMobile="isMobile"
                                :heading="$t('message.appointmentType')"
                                :selectionText="selectedAppointment.type.name"
                                :componentCurrentState="isTypeOpen"
                                :isActive="true"
                                :selectionMade="Boolean(selectedAppointment.type.name)"
                                @clicked="toggleTypeHeader"
                            />
                            <appointment-heading
                                id="tab-2"
                                aria-controls="tabpanel-2"
                                :isMobile="isMobile"
                                :heading="$t('message.pickDate')"
                                :selectionText="dateHeadingText"
                                :isActive="Boolean(selectedAppointment.type.id)"
                                :componentCurrentState="isCalendarOpen"
                                :selectionMade="Boolean(dateHeadingText)"
                                @clicked="toggleDateHeader"
                            />
                            <appointment-heading
                                id="tab-3"
                                aria-controls="tabpanel-3"
                                :isMobile="isMobile"
                                :heading="$t('message.pickTime')"
                                :selectionText="timeHeadingText"
                                :componentCurrentState="isTimeSlotsOpen"
                                :isActive="Boolean(dateHeadingText)"
                                :selectionMade="Boolean(selectedAppointment.time.appointmentTime)"
                                @clicked="toggleTimeHeader"
                            />
                        </div>

                        <article>

                            <!-- APPOINTMENT TYPE HEADING FOR MOBILE-->
                            <appointment-heading
                                v-if="isMobile"
                                :isMobile="isMobile"
                                :heading="$t('message.appointmentType')"
                                :selectionText="selectedAppointment.type.name"
                                :componentCurrentState="isTypeOpen"
                                :isActive="true"
                                :selectionMade="Boolean(selectedAppointment.type.name)"
                                @clicked="toggleTypeHeader"
                            />

                            <!-- PICK YOUR APPOINTMENT TYPE -->
                            <appointment-type
                                id="tabpanel-1"
                                role="tabpanel"
                                aria-labelledby="tab-1"
                                :query="query"
                                :isMobile="isMobile"
                                :isTypeOpen="isTypeOpen"
                                @updateAppointmentType="updateAppointmentTypeSelected"
                                @mobileInstallPicked="mobileInstallPicked"
                                :setShowChooseNewTireButton="setShowChooseNewTireButton"
                            />
                            <!-- APPOINTMENT DATE HEADING FOR MOBILE -->
                            <appointment-heading
                                v-if="isMobile"
                                :isMobile="isMobile"
                                :heading="$t('message.pickDate')"
                                :selectionText="dateHeadingText"
                                :componentCurrentState="isCalendarOpen"
                                :isActive="Boolean(selectedAppointment.type.id)"
                                :selectionMade="Boolean(dateHeadingText)"
                                @clicked="toggleDateHeader"
                            />

                            <!-- PICK YOUR APPOINTMENT DATE -->
                            <appointment-date
                                id="tabpanel-2"
                                role="tabpanel"
                                aria-labelledby="tab-2"
                                v-show="deliveryDate"
                                :query="query"
                                :isMobile="isMobile"
                                :isCalendarOpen="isCalendarOpen"
                                :selectedAppointmentType="selectedAppointment.type"
                                @updateAppointmentDate="updateAppointmentDateSelected"
                                @fetchedTimeSlots="updateTimeSlots"
                                @toggleSubmitVisibility="toggleSubmitVisibility"
                            />

                            <!-- APPOINTMENT TIME HEADING FOR MOBILE -->
                            <appointment-heading
                                v-if="isMobile"
                                :isMobile="isMobile"
                                :heading="$t('message.pickTime')"
                                :selectionText="timeHeadingText"
                                :componentCurrentState="isTimeSlotsOpen"
                                :isActive="Boolean(dateHeadingText)"
                                :selectionMade="Boolean(selectedAppointment.time.appointmentTime)"
                                @clicked="toggleTimeHeader"
                            />
                            <!-- PICK YOUR APPOINTMENT TIME -->
                            <appointment-time
                                id="tabpanel-3"
                                role="tabpanel"
                                aria-labelledby="tab-3"
                                :query="query"
                                :isMobile="isMobile"
                                :isTimeSlotsOpen="isTimeSlotsOpen"
                                :timeSlots="fetchedTimeSlots"
                                :date="selectedAppointment.date"
                                :provider="selectedAppointment.provider"
                                @updateAppointmentTime="updateAppointmentTimeSelected"
                            />

                            <!-- SERVICES HEADING FOR MOBILE -->
                            <appointment-heading
                                v-if="isMobile && selected.serviceOfferings && selected.serviceOfferings.length > 0 && selectedAppointment.type.id !== 13"
                                :isMobile="isMobile"
                                :heading="selectedAppointment.services.length ? selectedAppointment.services.map(service => service.title).join(', ') : $t('message.additionalServicesOptOptional')"
                                :componentCurrentState="areServicesOpen"
                                :isActive="selectedAppointment.provider !== 'SL' && selectedAppointment.services.length ? true : Boolean(selectedAppointment.time.apptDateTime)"
                                :selectionMade="Boolean(selectedAppointment.services.length)"
                                @clicked="toggleServicesHeader"
                            />
                            <!-- PICK YOUR APPOINTMENT SERVICES -->
                            <appointment-services
                                v-if="isMobile && selected.serviceOfferings && selected.serviceOfferings.length > 0"
                                :isMobile="isMobile"
                                :query="query"
                                :timeChosen="selectedAppointment.time.appointmentTime"
                                :date="selectedAppointment.date"
                                :areServicesOpen="areServicesOpen"
                                @updateAppointmentServices="updateAppointmentServicesSelected"
                            />

                        </article>

                    </section>

                    <!-- CONSUMER COMMENTS FOR MOBILE -->
                    <label
                        class="services__input-label services__comments-label fs-mask"
                        for="serviceComments"
                        v-if="isMobile"
                    >
                        {{$t("message.comments")}} ({{$t('message.optional')}})
                        <textarea
                            id="serviceComments"
                            name="serviceComments"
                            maxlength="400"
                            rows="3"
                            :placeholder="$t('message.servicesPlaceholder')"
                            v-model="selectedAppointment.comments"
                            data-vv-name="comments"
                            v-validate="{ regex: /^[a-zA-Z0-9 ,=/'@&()?$#.!\n\-]*$/ }"
                            :class="['services__comments-textarea',
                                     {'services__comments-textarea--error': errors.has('comments')}]"
                            data-cy="scheduleComments"
                        ></textarea>
                    </label>
                    <small
                        v-if="isMobile && errors.has('comments')"
                        class="input__error-reason form__error"
                    >{{ $t("message.commentsError") }}</small>

                    <!-- CONSUMER FINANCING OFFER -->
                    <div v-if="financingProductAllowed && isMobile" class="row">
                        <div v-if="!isCreditCard" class="col-12 schedule__finance-disclaimer">
                            <img :src="financePartnerLogoURL" :alt="financePartnerLogoName" />
                            <span v-html="financeShortText"></span>
                        </div>
                    </div>

                    <!-- SUBMISSION BUTTON -->
                    <div class="submission" v-if="showRequestAppointmentButton">
                        <stateful-button
                            :disabled="submitButtonDisabled"
                            :isLoading="isSubmittingAppointment"
                            @statefulButtonClicked="submitAppointment"
                            class="button button--primary"
                            data-cy="scheduleButton"
                            v-if="!showChooseNewTireButton"
                        >{{$t("message.scheduleBtn")}}
                        </stateful-button>
                        <div v-else
                             class="button button--primary choose-new-tire"
                             @click="chooseNewTires"
                        >
                            {{$t("message.chooseNewTires")}}
                        </div>
                    </div>

                </form>
            </div>
            <div v-else :class="{ 'col-12': isMobile, 'col-7': !isMobile }">
                <div
                    class="calendar__loader"
                    aria-live="assertive"
                    :aria-label="$t('message.loading')"
                    role="alert"
                    aria-busy="true"
                >
                    <img
                        src="/dist/assets/loaders/tire-spinner.gif"
                        alt="Spinning loader animation"
                    />
                </div>
            </div>

            <content-container contentType="ad" />

        </section>
    </div>
</template>

<script>

import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { isEmpty, extend } from 'underscore';
import ContentContainer from '@/components/ContentContainer.vue';
import AppointmentType from '@/components/AppointmentType.vue';
import AppointmentDate from '@/components/AppointmentDate.vue';
import AppointmentTime from '@/components/AppointmentTime.vue';
import AppointmentServices from '@/components/AppointmentServices.vue';
import AppointmentHeading from '@/components/AppointmentHeading.vue';
import ModalOutOfStock from '../components/ModalOutOfStock.vue';
import DialogModal from '@/components/DialogModal';

import { isAfter } from 'date-fns';
import { windowSizeMixin } from '@/mixins/windowSizeMixin';
import axios from 'axios';
import {
	scheduleOk,
	schedule500Error,
	scheduleOOSError,
	scheduleProviderSet,
	scheduleReferrerType,
	submitAppointmentTypeOk,
	submitAppointmentDateOk,
	submitAppointmentTimeOk,
	additionalServicesAdded
} from '@/utils/setDataLayer';
import StatefulButton from '@/components/StatefulButton.vue';
import ModalFslMobileTransaction from '@/components/ModalFslMobileTransaction.vue';


export default {
	name: 'Appointment',

	props: {
		query: {
			type: Object,
			required: true
		}
	},

	mixins: [windowSizeMixin],

	components: {
		AppointmentHeading,
		AppointmentType,
		AppointmentDate,
		AppointmentTime,
		AppointmentServices,
		ContentContainer,
		ModalOutOfStock,
		StatefulButton,
		ModalFslMobileTransaction,
		DialogModal
	},

	data() {
		return {
			// open/close accordions
			isTypeOpen: true,
			isCalendarOpen: false,
			isTimeSlotsOpen: false,
			areServicesOpen: false,
			// is appointment able to be submitted
			submitButtonDisabled: true,
			showRequestAppointmentButton: true,
			// full appointment object, updated as user moves through the page
			selectedAppointment: {
				// type emitted from appointmentType component
				type: {
					id: 0,
					name: ''
				},
				// date emitted from appointmentDate component - must be of type Date()
				date: new Date(),
				// time object emitted from appointmentTime component
				time: {
					offset: 0,
					apptDateTime: '',
					blockStartTime: '',
					appointmentDate: '',
					appointmentTime: '',
					blockDurationMinutes: 0
				},
				mobileInstallZip: '',
				// services emitted from appointmentServices component
				services: [],
				// Schedule Lite, XTime, TimeHighway, etc - emitted from updateTimeSlots event
				provider: '',
				// comments added by user
				comments: ''
			},
			// timeSlots emitted from
			fetchedTimeSlots: [],
			// Max days out you can schedule an appointment
			maxApptDays: 90,
			// Hours to add to the expected delivery time to provide a delivery buffer
			deliveryBufferHours: 0,
			// interval of appointments from provider
			interval: 60,
			// minimum delivery buffer hours. Always added to delivery buffer hours from the dealer
			minDeliveryBufferHours: 24,
			// Zip entered for Mobile Installation location
			mobileInstallZip: '',
			// Used for Mobile responsiveness
			windowSizeMixinWidth: 0,
			isLoading: true,
			// check to see if user is referred from external application (i.e. DRSG)
			referrer: '',
			year: 0,
			showChooseNewTireButton: false
		};
	},

	inject: {
		$validator: '$validator'
	},

	methods: {
		...mapActions('appointment', ['setAppointment', 'getAppointment', 'setMobileInstallZip', 'getMobileInstallZip']),
		...mapActions('cart', ['fetchCart']),
		...mapActions('content/schedule', ['fetchContent']),
		...mapActions('dealer', ['getDealer', 'select']),
		...mapActions('products', ['getInventory']),
		...mapMutations('content/schedule', ['resetTransportInfo']),
		...mapMutations('modal', ['toggleFslModal']),
		...mapMutations('products', ['checkStaggered', 'setProductName']),
		...mapActions('carline', ['getCarlineCatalogYears']),

		setDataLayer() {
			// in reference to referral from external site (typically drsg or promo)
			scheduleReferrerType(this.query.referrer ? this.query.referrer : 'non-referral');
		},
		// ToggleCTA handles the emitted event from calendar component
		toggleTypeHeader() { // eslint-disable-line complexity
			if (!this.isMobile) {
				this.isCalendarOpen = false;
				this.isTimeSlotsOpen = false;
			}

			this.isTypeOpen = this.isTypeOpen && !this.isMobile ? true : !this.isTypeOpen;
		},
		toggleDateHeader() { // eslint-disable-line complexity
			if (this.selectedAppointment.type.id) {
				if (!this.isMobile) {
					this.isTypeOpen = false;
					this.isTimeSlotsOpen = false;
				}
				this.isCalendarOpen = this.isCalendarOpen && !this.isMobile ? true : !this.isCalendarOpen;
			}
		},
		toggleTimeHeader() { // eslint-disable-line complexity
			if (isAfter(this.selectedAppointment.date, new Date())) {
				if (!this.isMobile) {
					this.isTypeOpen = false;
					this.isCalendarOpen = false;
				}
				this.isTimeSlotsOpen = this.isTimeSlotsOpen && !this.isMobile ? true : !this.isTimeSlotsOpen;
			}
		},
		toggleServicesHeader() { // eslint-disable-line complexity
			// this only shows on mobile, so no need to account for others on desktop
			if (this.selectedAppointment.time.appointmentTime || this.selectedAppointment.services.length > 0) {
				this.areServicesOpen = !this.areServicesOpen;
			}
		},
		toggleSubmit(isDisabled) {
			this.submitButtonDisabled = isDisabled;
		},
		toggleSubmitVisibility(isDisabled) {
			this.showRequestAppointmentButton = isDisabled;
		},
		updateTimeSlots({ openDates, interval, provider }) {
			this.fetchedTimeSlots = openDates;
			this.interval = interval;
			this.selectedAppointment.provider = provider;
			scheduleProviderSet(provider);
		},
		updateAppointmentTypeSelected(appointmentTypeSelected, mobileInstallZip) { //eslint-disable-line

			if (mobileInstallZip) {
				this.selectedAppointment.mobileInstallZip = mobileInstallZip;
				// TODO: update this to add the mobile install fee once it exists.
				this.selectedAppointment.services = [this.selected.serviceOfferings.find(serv => serv.transportID === 13)];
			}

			if (this.selectedAppointment.provider !== 'SL' || !appointmentTypeSelected.id || this.selectedAppointment.type.id === 13) {
				this.selectedAppointment.time = {
					offset: 0,
					apptDateTime: '',
					blockStartTime: '',
					appointmentDate: '',
					appointmentTime: '',
					blockDurationMinutes: 0
				};
				this.selectedAppointment.date = new Date();
				this.dateHeadingText = '';
				this.timeHeadingText = '';
				this.areServicesOpen = false;
			}
			this.selectedAppointment.type = appointmentTypeSelected;
			this.$nextTick(() => {
				if (this.isMobile) {
					window.scrollTo(0, 250);
				}
			});

			// check to see if user has already filled out other options
			this.isTypeOpen = Boolean(isAfter(this.selectedAppointment.date, new Date()) || !this.selectedAppointment.type.id);
			this.isCalendarOpen = Boolean(!this.dateHeadingText && this.selectedAppointment.type.id);
			this.$nextTick(() => {
				if (this.isCalendarOpen) {
					document.getElementById('tab-2').focus();
				}
			});
			this.submitButtonDisabled = Boolean(!this.timeHeadingText);
			submitAppointmentTypeOk(appointmentTypeSelected);
		},
		updateAppointmentDateSelected(appointmentDateSelected) {
			this.selectedAppointment.date = appointmentDateSelected;
			this.timeHeadingText = '';
			this.isCalendarOpen = false;
			this.isTimeSlotsOpen = true;
			this.$nextTick(() => {
				if (this.isTimeSlotsOpen) {
					document.getElementById('tab-3').focus();
				}
			});
			this.submitButtonDisabled = true;
			submitAppointmentDateOk(appointmentDateSelected);
		},
		updateAppointmentTimeSelected(appointmentTimeSelected) {
			this.selectedAppointment.time = appointmentTimeSelected;
			this.selectedAppointment.time.blockDurationMinutes = this.interval;
			this.isTimeSlotsOpen = !this.isMobile;
			this.submitButtonDisabled = false;
			this.areServicesOpen = true;
			submitAppointmentTimeOk(appointmentTimeSelected.appointmentTime);
		},
		updateAppointmentServicesSelected(appointmentServicesSelected) {
			this.selectedAppointment.services = appointmentServicesSelected;
			additionalServicesAdded(appointmentServicesSelected);
		},
		mobileInstallPicked(val) {
			// const defaultServices = this.selectedAppointment.services.length ? this.selectedAppointment.services.filter(serv => !serv.transportID) : [];

			// this.selectedAppointment.services = val ? this.selected.serviceOfferings.find(serv => serv.transportID === 14) || [] : defaultServices;
			if (val === true) {
				this.toggleFslModal();
			}
		},
		async submitAppointment() {
			const isInputValid = await this.$validator.validateAll();

			if (isInputValid) {
				const { appointmentDate, appointmentTime } = this.selectedAppointment.time;

				await this.setAppointment(this.selectedAppointment);
				scheduleOk({
					appointmentDate,
					appointmentTime,
					transportation: this.selectedAppointment.type.name,
					provider: this.selectedAppointment.provider
				});
				let r = this.$router.resolve({
					path: '/app/payment',
					query: extend(this.query, {
						prov: this.selectedAppointment.provider
					})
				});

				return window.location.assign(r.href);
			}

			return schedule500Error(this.selectedAppointment.provider);

		},
		setShowChooseNewTireButton(show) {
			this.showChooseNewTireButton = show;
		},
		chooseNewTires() {
			const queryValues = { ...this.query };

			// Remove useless values from query
			delete queryValues.localBest;
			delete queryValues.qty;
			delete queryValues.sku;
			this.$router.push({
				path: '/app/product',
				query: queryValues
			});
		}
	},

	computed: {
		...mapState({ qtySelected: state => state.products.qtySelected }),
		...mapState('content/schedule', ['trivia',
			'promo',
			'dealerSpecialPromo',
			'ad',
			'headline'
			// 'mobileInstallContent1'
		]),
		...mapState('initial', ['title']),
		...mapState('cart/order', ['defaultTransportation', 'details']),
		...mapState('dealer', ['selected']),
		...mapState('cart', ['tiresInCart']),
		...mapState('products', ['qtySelected', 'defaultQty', 'isStaggered', 'deliveryDate', 'selectedProdName']),
		...mapState('modal', ['oos']),
		...mapState('consumerInfo', ['userLanguage']),
		...mapState('appointment', ['isSubmittingAppointment']),
		...mapGetters('carline', { years: 'sortYears' }),

		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.appointmentPage') + ' | ' + this.title;

			return page;
		},
		// The formatted heading once a user has selected a date
		dateHeadingText: {
			get() {
				if (isAfter(this.selectedAppointment.date, new Date())) {
					return this.$d(this.selectedAppointment.date, 'short');
				}

				return '';
			},
			set(string) {
				return string;
			}
		},
		// The formatted heading once a user has selected a time
		timeHeadingText: {
			get() {
				if (this.selectedAppointment.time.apptDateTime) {
					return this.$d(this.selectedAppointment.time.apptDateTime, 'time');
				}

				return '';
			},
			set(string) {
				return string;
			}
		},
		isSearchBySize() {
			return Boolean(this.query.isbysize);
		},
		applicableTrivia() {
			// ranking order: Dealer special promotion > product promotion > standard trivia
			const promoContentTypes = [this.dealerSpecialPromo, this.promo];
			const promoTriviaAvailable = promoContentTypes.find(trivia => trivia.includes('promotionTrivia'));

			return promoTriviaAvailable || this.trivia;
		},
		financeShortText() {
			if (!this.selected.financePartner) {
				return null;
			}

			return this.selected.financePartner.appointmentShortText;

		},
		financePartnerLogoName() {
			if (this.selected.financePartner) {
				return `${this.$t('message.financing')} ${this.$t('message.availableThrough') } ${ this.selected.financePartner.description}`;

			}

			return `finance partner logo`;
		},
		financePartnerLogoURL() {
			if (this.selected.financePartner) {
				return `/dist/assets/finance/${this.selected.financePartner.code}_logo.png`.toLowerCase();
			}

			return null;
			// return credit card svg image
		},
		financingProductAllowed() { //eslint-disable-line
			return this.tiresInCart.products && this.selected.hasFinancingOTS && this.selected.financePartnerCode && !this.isWheelPackage;
		},
		isCreditCard() {
			if (this.selected.financePartner && this.selected.financePartner.disclaimerText2) {
				return true;
			}

			return false;
		},
		isMobile() {
			return this.$windowSizeMixinWindowWidthIsMobile;
		},
		hasMobileInstall() {
			for (const i in this.selected.transports) {
				if (this.selected.transports[i].id === 13) {

					return true;
				}
			}

			return false;
		},
		// This will result in the OOS modal popping for non-drsg referrals
		isOOS() {
			return (this.oos && !(this.referrer.toLowerCase() === 'drsg'));
		},
		// This is result in the modal specific to DRSG referrals popping instead of the default OOS modal
		isDrsgOOS() {
			return (this.oos && this.referrer.toLowerCase() === 'drsg');
		}
	},

	watch: {
		'scheduleSubmitted': function() {
			this.setMobileInstallZip(this.mobileInstallZip);
		},
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},

	async created() { // eslint-disable-line complexity
		this.isLoading = true;

		await this.setDataLayer();

		await this.checkStaggered(this.query.sku);

		if (!this.selected.websiteUID) {
			await this.select({ uid: this.query.uid });
		}

		// if searchbysize, get model and year and set query
		if (!this.query.year || !this.query.model) {

			// get year for search by size
			await this.getCarlineCatalogYears();
			this.year = this.years[0];

			const { data: { model, year }, err } = await axios.get('/service/carlineCatalogModelsBySizeRequest',
				{
					params: {
						year: this.year,
						make: this.query.make,
						isSearchBySize: true
					}
				});

			// If no models return for the current year, subtract and try again
			if (err) {
				throw new Error('carlinecatalog error');
			}
			this.query.year = year;
			this.query.model = model;
		}
		await this.fetchCart(this.query);

		await Promise.all([
			await this.getInventory({
				products: this.tiresInCart.products,
				qty: isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected
				// eslint-disable-next-line complexity
			}),
			this.fetchContent()
		]).then((prodMap) => {
			// In the event that the selected product is OOS when attempting to create
			// an appointment, we will use the product name generated below in a corrresponding modal
			if (this.oos || !prodMap) {
				this.setProductName(
					((this.tiresInCart.products).pop()).catalogEntryDescription
				);

				// Sets datalayer - can be used for tagging related to tracking OOS on appointment page
				return scheduleOOSError({
					referrer: this.query.referrer,
					sku: this.query.sku
				});
			}

			return true;
		});
		this.isLoading = false;
	},

	mounted() {
		document.title = this.newPageTitle;
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (!vm.$route.query.make && !vm.$route.query.sku && !vm.$route.query.qty) {
				return vm.$router.push('/');
			}

			return true;
		});
	}
};
</script>
